<template>
  <v-row justify="center" v-if="navigation">
    <v-dialog v-model="carDialog" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="text-h5"> {{ carPositionTitle }}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col class="col-12">
                <v-btn block rounded @click="showCar">{{
                  $t("showMyCar")
                }}</v-btn>
              </v-col>
              <v-col class="col-12">
                <v-btn block rounded @click="removePosition">{{
                  $t("removePosition")
                }}</v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red darken-1" text @click="closePopup">
            {{ $t("close") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { postAnalysesDataAsync } from "../services/services";

export default {
  props: ["carDialog", "carPositionTitle"],
  //This is the component that opens when user has car location
  data: () => ({
    languages: [
      { label: "Türkçe", value: "tr" },
      { label: "English", value: "en" },
      { label: "عربى", value: "ar" },
    ],
  }),
  methods: {
    async closePopup() {
      this.$emit("closeCarPopup");

      await postAnalysesDataAsync({
        eventKey: "CAR_BUTTON",
        eventData: {
          action: localStorage.carPositionFloor
            ? "closeWithCar"
            : "closeWithoutCar",
          timestamp: Math.floor(new Date().getTime() / 1000),
        },
      });
    },
    async removePosition() {
      localStorage.removeItem("carPosition");
      localStorage.removeItem("carPositionId");
      localStorage.removeItem("carPositionFloor");
      localStorage.removeItem("carPositionCoordinates");
      this.$emit("closeCarPopup");
      await postAnalysesDataAsync({
        eventKey: "CAR_BUTTON",
        eventData: {
          action: "closeWithoutCar",
          timestamp: Math.floor(new Date().getTime() / 1000),
        },
      });
    },
    async showCar() {
      this.$store.getters.stores.forEach((element) => {
        if (element.id === localStorage.getItem("carPositionId")) {
          let place = {
            title: element.title,
            Id: element.id,
            floor: element.location.properties.floorLevel,
            center: element.location.geometry.coordinates,
          };

          this.$store.dispatch("getStoreLocation", place);
          const shopIcon = element.category ? element.category.icon : "";
          const tags = element.tags ? element.tags : [];
          const zoneId = element.properties
            ? element.properties.zone_id
              ? element.properties.zone_id
              : ""
            : "";

          this.$store.dispatch("shopInfo", {
            title: element.title,
            category: element.category,
            icon: shopIcon,
            properties: element.properties,
            tags: tags,
            zoneId: zoneId,
            isCluster: false,
            center: element.location.properties.shopCenterPoint,
          });
        }
      });

      // let route = {
      //   floor: localStorage.getItem("carPositionFloor"),
      //   center: JSON.parse(localStorage.getItem("carPositionCoordinates")),
      //   startPoint: localStorage.getItem("carPositionId"),
      //   endPoint: localStorage.getItem("carPositionId"),
      //   title: localStorage.getItem("carPosition"),
      // };

      await postAnalysesDataAsync({
        eventKey: "CAR_BUTTON",
        eventData: {
          action: "showMyCar",
          timestamp: Math.floor(new Date().getTime() / 1000),
        },
      });
      this.$emit("closeCarPopup");
    },
  },
  computed: {
    navigation() {
      const paramsNavigationConfig =
        this.$store.getters.urlParamsConfigs.navigation;
      const navigationConfig = this.$store.getters.placeConfig.navigation;

      if (paramsNavigationConfig === false && navigationConfig === true)
        return false;
      return navigationConfig;
    },
  },
};
</script>
