import { render, staticRenderFns } from "./DestinationBox.vue?vue&type=template&id=d158a700&scoped=true"
import script from "./DestinationBox.vue?vue&type=script&lang=js"
export * from "./DestinationBox.vue?vue&type=script&lang=js"
import style0 from "./DestinationBox.vue?vue&type=style&index=0&id=d158a700&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d158a700",
  null
  
)

export default component.exports